
import {
    createVNode
} from 'vue';
import {
    ExclamationCircleOutlined,
    DeleteOutlined
} from '@ant-design/icons-vue';
import TicketDetail from "./component/detail/index.vue"
export default {
    name: "BasicAddressBook",
    components: {
        DeleteOutlined,
        TicketDetail
    },
    data() {
        return {
            url: '/admin/ticket/list',
            // 表格搜索条件
            where: {},
            // 表格选中数据
            selection: [],
            // 当前编辑数据
            current: null,
            // 是否显示编辑弹窗
            showEdit: false,
            // 是否显示详情
            visibleDetail: false
        };
    },
    computed: {
        columns() {
            return [{
                    key: 'index',
                    width: 48,
                    customRender: ({
                        index
                    }) => this.$refs.table.tableIndex + index
                },
                {
                    key: 'create_user_company_name',
                    title: this.$t('创建人'),
                    dataIndex: 'create_user_company_name',
                    width: 150,
                    sorter: true
                },
                {
                    title: this.$t('Ticket Number'),
                    dataIndex: 'ticket_number',
                    width: 150,
                    sorter: true,
                    slots: {
                        customRender: 'ticket_number'
                    }
                },
                {
                    key: 'ticket_type_title',
                    title: this.$t('Ticket Type'),
                    dataIndex: 'ticket_type_title',
                    sorter: true,
                    width: 250
                },
                {

                    key: 'ticket_title',
                    title: this.$t('Ticket Title'),
                    dataIndex: 'ticket_title',
                    // width: 200,
                    sorter: true,
                    slots: {
                        customRender: 'name'
                    }
                },
                {
                    key: 'ticket_status',
                    title: this.$t('Ticket Status'),
                    dataIndex: 'ticket_status',
                    sorter: true,
                    width: 80,
                    customRender: ({
                        text
                    }) => text !== undefined ? this.$t(`工单状态.${text}`) : ''

                },
                {
                    key: 'update_time',
                    title: this.$t('更新时间'),
                    dataIndex: 'create_time',
                    width: 180,
                    sorter: true
                },
                {
                    key: 'create_time',
                    title: this.$t('创建时间'),
                    dataIndex: 'create_time',
                    width: 180,
                    sorter: true
                },
                {
                    title: this.$t('操作'),
                    key: 'action',
                    width: 120,
                    slots: {
                        customRender: 'action'
                    }
                }
            ]
        }
    },
    methods: {
        /* 搜索 */
        reload() {
            this.selection = [];
            this.$refs.table.reload({
                page: 1
            });
        },
        /*  重置搜索 */
        reset() {
            this.where = {};
            this.$nextTick(() => {
                this.reload();
            });
        },
        /**
         * 新建
         */
        createNew() {
            this.current = null;
            this.showEdit = true;
        },
        /* 打开编辑弹窗 */
        openEdit(row) {
            this.current = row;
            this.showEdit = true;
        },
        /* 批量删除 */
        removeBatch(row) {
            row && (this.selection = [row]);
            if (!this.selection.length) {
                return this.$message.error('请至少选择一条数据');
            }

            this.$confirm({
                title: this.$t('提示'),
                content: this.$t('确定要删除选中的数据吗?'),
                icon: createVNode(ExclamationCircleOutlined),
                maskClosable: true,
                onOk: () => {
                    const hide = this.$message.loading('请求中...', 0);

                    this.$http.post('/admin/ticket/delete', {
                        ids: this.selection.map(d => d.id)
                    }).then(res => {
                        hide();
                        if (res.data.code === 0) {
                            this.$message.success(res.data.msg);
                            this.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        hide();
                        this.$message.error(e.message);
                    });
                }
            });
        },
        // 打开对话详情
        showMessage(row) {
            this.current = row;
            this.visibleDetail = true;
        },
        closeTicket(row) {
            if (row.ticket_status === 3) {
                return;
            }
            this.$confirm({
                title: this.$t("提示"),
                content: this.$t("是否确认关闭"),
                icon: createVNode(ExclamationCircleOutlined),
                maskClosable: true,
                onOk: () => {
                    this.$http.get(`/user/ticket/close/${row.id}`).then(res => {
                        if (res.data.code == 0) {
                            this.$message.success(res.data.msg)
                            this.$refs.table.reload();
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    })
                }
            })
        }
    }
}
